export class OidcFlow {
  constructor(config) {
    // General
    this.businessContext = config.businessContext;

    // OIDC
    this.clientId = config.clientId;
    this.oidcTokenStorageKey = 'oidcToken_' + config.businessContext;
    this.accessTokenValidityMs = config.accessTokenValidityMs;
    this.authUrl = `https://${config.wenEnvironment}/auth/oauth2/realms/root/realms/${config.realm}`;
    this.authRealm = config.realm;
    this.acrValues = config.acr_values;
    this.wenAuthorizationHeader = 'Basic ' + btoa(unescape(encodeURIComponent(`${config.clientId}:`)));

    this.setSessionId();
  }

  setSessionId() {
    let id = sessionStorage.getItem('cavors-session-id');
    if (!id) {
      id = this.createRandomUuid();
      sessionStorage.setItem('cavors-session-id', id);
    }
  }

  start() {
    if (this.oidcIsSupported()) {
      this.startOrContinueOidcFlow();
    } else {
      this.onAuthCompleted();
    }

    if (this.getQueryParam('terminalServer')) {
      localStorage.setItem('terminalServer', 'true');
    }
  }

  startOrContinueOidcFlow() {
    console.log(this.logPrefix('Auth') + 'Starting or continuing OIDC flow.');
    if (this.getOidcTokenFromStorage()) {
      this.refreshAccessTokenIfExpired()
        .then(this.onAuthCompleted.bind(this))
        .catch(this.redirectToLoginPage.bind(this));
    } else if (this.authorizationCodeIsInUrl()) {
      this.requestAccessToken()
        .then(this.onAuthCompleted.bind(this))
        .catch(this.redirectToLoginPage.bind(this));
    } else {
      this.redirectToLoginPage();
    }
  }

  onAuthCompleted() {
    if (this.oidcIsSupported()) {
      this.scheduleTimerForAccessTokenRefresh();
    }
    this.requestUserData()
      .then(this.handleUserData.bind(this))
      .catch(() => {
        // Do nothing
      });
  }

  oidcIsSupported() {
    // return false;
    return this.clientId.length > 0;
  }

  scheduleTimerForAccessTokenRefresh() {
    setInterval(() => {
      this.refreshAccessTokenIfExpired().catch();
    }, 30000);
  }

  dispatchOidcTokenRefreshedEvent() {
    window.dispatchEvent(new CustomEvent('oidcTokenRefreshed', {detail: this.getOidcTokenFromStorage()}));
  }

  handleUserData(user) {
    if (user.b2dUser && user.businessPartners.length === 0) {
      console.log(this.logPrefix('Auth') + 'User has no business relationships in S-Gate.');
      this.showError('Your user account seems to have no business relationships in S-Gate.');
    } else {
      window['bmwNamespace'] = {userData: user};
      window.dispatchEvent(new CustomEvent('userDataLoaded'));
    }
  }

  refreshAccessTokenIfExpired() {
    const tokenObj = JSON.parse(this.getOidcTokenFromStorage());

    if (!tokenObj) {
      console.error(this.logPrefix('Auth') + 'Cannot refresh OIDC access token, local storage is empty.');
      return Promise.reject();
    } else if (this.isAccessTokenExpired(tokenObj)) {
      console.log(this.logPrefix('Auth') + 'OIDC access token expired. Refreshing...');
      return this.refreshAccessToken(tokenObj);
    } else {
      console.debug(this.logPrefix('Auth') + 'OIDC access token is still fresh.');
      return Promise.resolve();
    }
  }

  isAccessTokenExpired(token) {
    return !token
      || !token.access_token_timestamp_ms
      || (new Date()).getTime()
      > token.access_token_timestamp_ms
      + this.accessTokenValidityMs;
  }

  refreshAccessToken(tokenObj) {
    return new Promise((resolve, reject) => {
      const refreshRequest = this.buildRefreshOidcAccessTokenRequest(tokenObj.refresh_token);
      const url = this.authUrl + '/access_token';
      const reqTimestampMs = (new Date()).getTime();

      fetch(url, refreshRequest)
        .then(response => {
          this.logResponseTime('POST', url, response.status, reqTimestampMs);
          if (response.status === 200) {
            response.json().then(newTokenObj => {
              this.handleSuccessfulAccessTokenRefresh(tokenObj, newTokenObj);
              resolve();
            });
          } else {
            this.handleFailedAccessTokenRefresh(tokenObj, response);
            reject();
          }
        })
        .catch(error => {
          console.error(this.logPrefix('Auth')
            + `Network error while retrieving OIDC access token from ${url}: ${error}.`);
          this.showError(`Authentication failed due to a network error.`);
          reject(error);
        });
    });
  }

  buildRefreshOidcAccessTokenRequest(refreshToken) {
    return {
      method: 'POST',
      headers: {
        'authorization': this.wenAuthorizationHeader,
        'content-type': 'application/x-www-form-urlencoded'
      },
      body: `grant_type=refresh_token&refresh_token=${refreshToken}&redirect_uri=${this.getRedirectUri()}`
    };
  }

  handleSuccessfulAccessTokenRefresh(tokenObj, newTokenObj) {
    const expiredAccessToken = tokenObj.access_token;
    tokenObj.access_token = newTokenObj.access_token;
    tokenObj.access_token_timestamp_ms = (new Date()).getTime();
    tokenObj.id_token = newTokenObj.id_token;
    this.putOidcTokenInStorage(tokenObj);
    this.revokeOidcAccessToken(expiredAccessToken, 2500);
    this.dispatchOidcTokenRefreshedEvent();
    console.log(this.logPrefix('Auth') + 'OIDC access token refresh successful.');
  }

  handleFailedAccessTokenRefresh(tokenObj, response) {
    response.json().then(errorObject => {
      console.error(this.logPrefix('Auth')
        + `Failed to refresh OIDC access token: HTTP ${response.status} ${response.statusText}.`, errorObject);
    }).catch(() => {
      console.error(this.logPrefix('Auth')
        + `Failed to refresh OIDC access token: HTTP ${response.status} ${response.statusText}.`);
    });
    if (response.status === 400 || response.status === 401) {
      this.removeOidcTokenFromStorage();
      this.revokeOidcAccessToken(tokenObj.access_token);
      this.redirectToLoginPage();
    } else {
      this.showError('Authentication failed due to an unknown application error.');
    }
  }

  authorizationCodeIsInUrl() {
    if (this.getQueryParam('error')) {
      throw new Error(this.getQueryParam('error') + ' - ' + this.getQueryParam('error_description'));
    }
    return this.getQueryParam('code') && this.getQueryParam('iss') && this.getQueryParam('client_id');
  }

  redirectToLoginPage() {
    console.log(this.logPrefix('Auth') + 'Redirecting to login page.');
    this.windowLocationReplace(this.getLoginPageUrl());
  }

  windowLocationReplace(url) {
    window.location.replace(url); // location.replace is readonly so spyOn can't replace it
  }

  getLoginPageUrl() {
    const url = new URL(this.authUrl + '/authorize');
    url.searchParams.set('client_id', this.clientId);
    url.searchParams.set('state', this.getApplicationState());
    url.searchParams.set('scope', this.getScopes());
    url.searchParams.set('redirect_uri', this.getRedirectUri());
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('nonce', '');
    if (this.acrValues) {
      url.searchParams.set('acr_values', this.acrValues);
    }
    return url.href;
  }

  getScopes() {
    if (this.businessContext === 'B2E') {
      return 'openid profile email phone address bmwids organization groups';
    } else {
      return 'openid profile email phone address bmwids organization b2xroles b2d';
    }
  }

  requestAccessToken() {
    return new Promise((resolve, reject) => {
      const headers = {};
      headers['content-type'] = 'application/x-www-form-urlencoded';
      headers['authorization'] = this.wenAuthorizationHeader;
      const body = `grant_type=authorization_code&code=${this.getQueryParam('code')}&redirect_uri=${this.getRedirectUri()}`;
      const url = this.authUrl + '/access_token';

      const reqTimestampMs = (new Date()).getTime();
      fetch(
        url,
        {
          method: 'POST',
          headers: headers,
          body: body
        }
      )
        .then(response => {
          this.logResponseTime('POST', url, response.status, reqTimestampMs);
          if (response.status === 200) {
            response.json().then(oidcTokenObject => {
              oidcTokenObject.access_token_timestamp_ms = (new Date()).getTime();
              oidcTokenObject.refresh_token_timestamp_ms = oidcTokenObject.access_token_timestamp_ms;
              oidcTokenObject.business_context = this.businessContext;
              oidcTokenObject.client_id = this.clientId;
              this.putOidcTokenInStorage(oidcTokenObject);
              this.dispatchOidcTokenRefreshedEvent();
              this.restoreDeepLink();
              resolve();
            });
          } else {
            console.log(this.logPrefix('Auth')
              + `Failed to retrieve OIDC access token: HTTP ${response.status} ${response.statusText}`);
            reject(response.statusText);
          }
        })
        .catch(error => {
          console.log(this.logPrefix('Auth')
            + `Network error while retrieving OIDC access token from ${url}: ${error}.`);
          reject(error);
        });

    });
  }

  requestUserData() {
    return new Promise((resolve, reject) => {
      const url = '/api/user/v1';
      const reqTimestampMs = (new Date()).getTime();
      fetch(
        url,
        {
          method: 'GET',
          headers: this.getHeaders()
        }
      ).then(response => {
        this.logResponseTime('GET', url, response.status, reqTimestampMs);
        if (response.status === 200) {
          response.json().then(json => {
            resolve(json.data);
          });
        } else {
          this.handleUserDataErrorResponse(response);
          reject(`Failed to retrieve user data from ${url}: HTTP ${response.status}.`);
        }
      }).catch(error => {
        reject(`Network error while retrieving user data from ${url}: ${error}.`);
      });
    });
  }

  getHeaders() {
    const headers = new Headers();
    const oidcToken = JSON.parse(this.getOidcTokenFromStorage()); // Potentially null
    const accessToken = oidcToken ? oidcToken.access_token : null;
    headers.set('access_token', accessToken);
    headers.set('authorization', 'Bearer ' + accessToken);
    headers.set('AuthRealm', this.authRealm);
    headers.set('AuthScheme', this.businessContext.substring(0, 3));
    headers.set('AuthType', 'WEN_TOKEN');
    headers.set('BusinessContext', this.businessContext);
    headers.set('cavors-request-id', this.createRandomUuid());
    const id = sessionStorage.getItem('cavors-session-id');
    if (!id) {
      console.warn(this.logPrefix('Auth') + 'CaVORS session id not set before logging.');
      this.setSessionId();
    }
    headers.set('cavors-session-id', sessionStorage.getItem('cavors-session-id') || '');
    return headers;
  }

  handleUserDataErrorResponse(response) {
    const message = `Failed to retrieve user data for business context ${this.businessContext}: HTTP ${response.status} ${response.statusText}.`;
    console.log(this.logPrefix('Auth') + message);
    if (response.status === 403) {
      if (this.businessContext === 'B2E') {
        this.showError('To request access to the AWP, please request the AWP user role in RightNow.');
      } else {
        this.showError('To request access to the AWP, please contact your S-Gate administrator.');
      }
    } else if (response.status < 200 || response.status >= 300) {
      // Check if we got a structured AWP error response with more specific information
      response.json().then(responseObject => {
        if (responseObject.message) {
          console.log(this.logPrefix('Auth') + 'Response: ', responseObject); // Should be { message, requestUrl,
                                                                              // statusCode }
          this.showError(responseObject.message);
        } else {
          this.showError(message);
        }
      }).catch(() => {
        this.showError(message);
      });
    }
  }

  getOidcTokenFromStorage() {
    return this.getLocalStorage().getItem(this.oidcTokenStorageKey);
  }

  putOidcTokenInStorage(token) {
    this.putItemInStorage(this.oidcTokenStorageKey, token);
  }

  putItemInStorage(key, item) {
    this.getLocalStorage().setItem(key, JSON.stringify(item));
  }

  removeOidcTokenFromStorage() {
    this.getLocalStorage().removeItem(this.oidcTokenStorageKey);
  }

  restoreDeepLink() {
    const state = this.getQueryParam('state');
    if (state) {
      console.log(this.logPrefix('Auth') + `OIDC flow complete. Restoring application state '${state}'.`);
      history.replaceState(null, '', state);
    } else {
      console.log(this.logPrefix('Auth') + 'OIDC flow complete. No application state to restore.');
    }
  }

  showError(msg) {
    let splashScreenMessage = document.getElementById('splash-screen-message');
    if (splashScreenMessage) {
      splashScreenMessage.innerText = msg;
      document.getElementById('splash-screen-loader').hidden = true;
      splashScreenMessage.hidden = false;
    }
  }

  getQueryParam(name) {
    return (new URL(this.getHref())).searchParams.get(name);
  }

  getHostname() {
    return window.location.hostname; // Allows for easier mocking in unit tests
  }

  getHref() {
    return window.location.href; // Allows for easier mocking in unit tests
  }

  getRedirectUri() {
    return this.getHostname() === 'localhost' ? 'http://localhost:4200' : `https://${this.getHostname()}`;
  }

  getApplicationState() {
    return window.location.pathname + window.location.search;
  }

  createRandomUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (this.getCryptoRandom() * 16) | 0; // eslint-disable-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line no-bitwise
      return v.toString(16);
    });
  }

  getCryptoRandom() {
    return window.crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
  }

  getLocalStorage() {
    return window.sessionStorage;
  }

  logResponseTime(reqMethod, reqUrl, resStatus, reqTimestampMs) {
    // Preflight request responses have a status of undefined and a duration of 1-5ms, so those need to be filtered out
    if (resStatus) {
      const resTimestampMs = (new Date()).getTime();
      const method = ('[' + reqMethod + ']').padStart(9);
      const url = '[' + reqUrl + ']';
      const status = '[' + resStatus + ']';
      const durationMs = '[' + (resTimestampMs - reqTimestampMs) + ']';
      console.log(this.logPrefix('Network') + `[Http-Res] ${method} ${url} ${status} in ${durationMs}ms`);
    }
  }

  revokeOidcAccessToken(accessToken, gracePeriodMs = 0) {
    const revokeRequest = this.buildRevokeOidcAccessTokenRequest(accessToken);
    const revokeUrl = this.authUrl + '/token/revoke';
    // Revoke the old token after a grace period to let ongoing requests finish
    setTimeout(() => this.sendRevokeOidcAccessTokenRequest(revokeUrl, revokeRequest), gracePeriodMs);
  }

  buildRevokeOidcAccessTokenRequest(accessToken) {
    return {
      method: 'POST',
      headers: {
        'authorization': this.wenAuthorizationHeader,
        'content-type': 'application/x-www-form-urlencoded'
      },
      body: `client_id=${this.clientId}&token=${accessToken}`
    };
  }

  sendRevokeOidcAccessTokenRequest(revokeUrl, revokeRequest) {
    fetch(revokeUrl, revokeRequest)
      .then(() => console.log(this.logPrefix('Auth') + 'Successfully revoked access token.'))
      .catch(() => console.log(this.logPrefix('Auth') + 'Failed to revoke access token.'));
  }

  logPrefix(component) {
    return (new Date()).toISOString() + ' [' + component + '] ';
  }
}
