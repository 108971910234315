<div>
  <span>{{ 'vehicle.mileage' | translate }}</span>
  <span>{{ mileage }}</span>
</div>
<div>
  <span>{{ 'vehicle.vin' | translate }}</span>
  <span>{{ vehicle?.getVin() || vehicle?.getVin7() }}</span>
</div>
<div>
  <span>{{ 'vehicle.productionDate' | translate }}</span>
  <span>{{ vehicle?.getProductionDate() | date:'mediumDate':'undefined':locale }}</span>
</div>
<div>
  <span>{{ 'vehicle.firstRegistrationDate' | translate }}</span>
  <span>{{ vehicle?.getFirstRegistrationDate()| date:'mediumDate':'undefined':locale }}</span>
</div>
<div>
  <span>{{ 'vehicle.warrantyStartDate' | translate }}</span>
  <span>{{ vehicle?.getWarrantyStartDate()| date:'mediumDate':'undefined':locale }}</span>
</div>
<div>
  <span>{{ 'vehicle.model' | translate }}</span>
  <span>{{ vehicle?.getModelDesignation() }}</span>
</div>
<div>
  <span>{{ 'vehicle.developmentSeries' | translate }}</span>
  <span>{{ vehicle?.getDevelopmentSeries() }}</span>
</div>
<div>
  <span>{{ 'vehicle.type' | translate }} ({{ 'vehicle.leadType' | translate }})</span>
  <span>{{ vehicle?.getTypeCode() + ' (' + vehicle?.getLeadType() + ')' }}</span>
</div>
<div>
  <span>{{ 'vehicle.bodyType' | translate }}</span>
  <span>{{ vehicle?.getBodyType() }}</span>
</div>
<div>
  <span>{{ 'keyPool.colorCode' | translate }}</span>
  <span>{{ vehicle?.getPaintNumber() + ' ' + vehicle?.getPaint() }}</span>
</div>
<div>
  <span>{{ 'keyPool.upholsteryCode' | translate }}</span>
  <span>{{ vehicle?.getUpholsteryCode() + ' ' + vehicle?.getUpholstery() }}</span>
</div>
<div>
  <span>{{ 'vehicle.engine' | translate }}</span>
  <span>{{ vehicle?.getEngineType() }}</span>
</div>
<div>
  <span>{{ 'vehicle.displacement' | translate }}</span>
  <span>{{ vehicle?.getCapacity() + ' ' + vehicle?.getCapacityUnit() }}</span>
</div>
<div>
  <span>{{ 'vehicle.transmission' | translate }}</span>
  <span>{{ vehicle?.getTransmissionType() }}</span>
</div>
<div>
  <span>{{ 'vehicle.steering' | translate }}</span>
  <span>{{ vehicle?.getSteering() }}</span>
</div>
