import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IBusinessPartner} from '@service-and-repairs/awpintegrationlib';
import {Observable} from 'rxjs';
import {Job} from '../../job/job';
import {ServiceCaseSessionService} from '../../service-case-session/services/service-case-session.service';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {ServiceCase} from '../models/service-case';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseService {

  constructor(private readonly httpClient: HttpClient,
              private readonly sessionService: ServiceCaseSessionService,
              private readonly translate: TranslateService,
              private readonly userService: UserService) {
  }

  private static getServiceCasesByVinParams(vin: string, user: UserData): URLSearchParams {
    const queryParams: string[][] = [
      [
        'assigned_distribution_partner_number',
        user.getContext() !== 'B2E' ? user.getBusinessPartner()?.getDistributionPartnerNumber() : undefined
      ],
      [
        'assigned_user_id',
        user.getContext() === 'B2E' ? user.getId() : undefined
      ],
      ['vin_short', vin.length === 7 ? vin : undefined],
      ['vin_long', vin.length === 17 ? vin : undefined]
    ].filter((param: string[]) => param[1]);

    return new URLSearchParams(queryParams);
  }

  private static removeExcessServiceCasePropertiesForUpdatingMetadata(serviceCase: ServiceCase): ServiceCase {
    const clone: ServiceCase = ServiceCase.fromPlainObject(serviceCase);
    // Jobs are needed for updating the service case session
    clone.setVehicle(null);
    clone.setCampaigns(undefined);
    clone.setServiceContracts(undefined);
    clone.setWarrantyRestrictions(undefined);
    clone.setWarnings([]);
    clone.setCustomers(undefined);
    return clone;
  }

  /**
   * Persists the service case in the backend. The external id (set by the backend service) is set in the service case.
   * @param newCase The service case to be persisted in the backend
   */
  createServiceCase(newCase: ServiceCase): Promise<ServiceCase> {
    return new Promise<ServiceCase>((resolve, reject): void => {
      this.httpCreateServiceCase(newCase).subscribe({
        next: (response: any): void => {
          const serviceCase: ServiceCase = ServiceCase.fromPlainObject(response);
          this.sessionService.createSession(serviceCase)
            .then((sccId: string): void => {
              serviceCase.setSccId(sccId);
              resolve(serviceCase);
            });
        },
        error: () => reject(new Error(this.translate.instant('case.notificationSaveError')))
      });
    });
  }

  /**
   * Updates the service case in the backend.
   * @param serviceCase The service case to be updated in the backend
   */
  updateMetadataInServiceCase(serviceCase: ServiceCase): Promise<ServiceCase> {
    this.updateServiceCaseCentral(serviceCase);

    return new Promise<ServiceCase>((resolve, reject): void => {
      this.httpUpdateMetadata(serviceCase).subscribe({
        next: (response: any): void => resolve(ServiceCase.fromPlainObject(response.data)),
        error: reject
      });
    });
  }

  /**
   Updates the given job in a service case in the backend.
   @param serviceCase service case
   @param job job to update
   */
  updateJobInServiceCase(serviceCase: ServiceCase, job: Job): Promise<Job> {
    this.updateServiceCaseCentral(serviceCase);

    return new Promise<Job>((resolve, reject): void => {
      this.httpUpdateJob(serviceCase.getExternalId(), job)
        .subscribe({
          next: (response: any) => resolve(Job.fromPlainObject(response.data)),
          error: reject
        });
    });
  }

  /**
   * Deletes the given job in a service case in the backend.
   * @param serviceCase service case
   * @param jobId external if of job to delete
   */
  deleteJobInServiceCase(serviceCase: ServiceCase, jobId: string): Promise<void> {
    this.updateServiceCaseCentral(serviceCase);

    return new Promise<void>((resolve, reject): void => {
      this.httpDeleteJob(serviceCase.getExternalId(), jobId).subscribe({
        next: () => resolve(),
        error: reject
      });
    });
  }

  /**
   * Retrieves a service case from the backend with the external id.
   * @param externalId External id of the service case to look up
   */
  getServiceCase(externalId: string): Promise<ServiceCase> {
    return new Promise<ServiceCase>((resolve, reject): void => {
      this.httpGetServiceCase(externalId).subscribe({
        next: (response: any): void => {
          const serviceCase: ServiceCase = ServiceCase.fromPlainObject(response);
          this.setAssignedBusinessPartnerIdIfMissing(serviceCase);
          this.updateOpenedOrCreateServiceCaseCentral(serviceCase);
          resolve(serviceCase);
        },
        error: (response: any) => reject(this.getLoadingFailedReason(response.statusCode))
      });
    });
  }

  /**
   * Retrieves service cases for a VIN modified within the last 42 days from the backend. In B2D context the service
   * cases are limited to the dealer outlet's service cases. In B2E the service cases are limited to the user's service
   * cases.
   * @param vin vehicle identification number
   */
  getServiceCasesByVin(vin: string): Promise<ServiceCase[]> {
    return new Promise<ServiceCase[]>((resolve, reject): void => {
      this.httpGetServiceCasesByVin(vin).subscribe({
        next: (response: any): void => {
          const serviceCases: ServiceCase[] = response.data.map((plainServiceCase: any) => {
            const serviceCase: ServiceCase = ServiceCase.fromPlainObject(plainServiceCase);
            this.setAssignedBusinessPartnerIdIfMissing(serviceCase);

            // ServiceCase.fromPlainObject clears demandCategories as the API does not return jobs.
            serviceCase.setDemandCategories(plainServiceCase.demandCategories);
            return serviceCase;
          });
          resolve(serviceCases);
        },
        error: (reason => {
          console.error(this.translate.instant('case.notificationLoadError') + ':' + reason);
          reject(new Error(this.translate.instant('case.notificationLoadError')));
        })
      });
    });
  }

  private updateServiceCaseCentral(serviceCase: ServiceCase): void {
    if (serviceCase.getSccId()) {
      this.sessionService.updateSession(serviceCase);
    }
  }

  private updateOpenedOrCreateServiceCaseCentral(serviceCase: ServiceCase): void {
    if (serviceCase.getSccId()) {
      this.sessionService.updateSessionLastOpened(serviceCase);
    }
  }

  private getLoadingFailedReason(statusCode: any): Error {
    let reason: string;
    if (statusCode?.valueOf() === 403) {
      reason = this.translate.instant('case.notificationLoadErrorNotAllowed');
    } else {
      reason = this.translate.instant('case.notificationLoadError');
      console.error('[Webapp][Service Case Service] ' + reason + ': ' + statusCode);
    }
    return new Error(reason);
  }

  // TODO: Remove once all service cases have a business partner ID (e.g. by applying an SQL script at deployment time).
  private setAssignedBusinessPartnerIdIfMissing(serviceCase: ServiceCase): void {
    const userData: UserData = this.userService.userSubject.getValue();
    if (userData?.isB2E()) {
      return;
    }
    if (!serviceCase.getAssignedBusinessPartnerId() || serviceCase.getAssignedBusinessPartnerId() === '') {
      const businessPartnerId: string = this.getBusinessPartnerIdForOutlet(
        serviceCase.getAssignedDistributionPartnerNumber(),
        serviceCase.getAssignedOutletNumber()
      );
      serviceCase.setAssignedBusinessPartnerId(businessPartnerId);
    }
  }

  private getBusinessPartnerIdForOutlet(distributionPartnerNumber: string, outletNumber: string): string {
    const userData: UserData = this.userService.userSubject.getValue();

    // Use current business partner ID as default
    let businessPartnerId: string = userData?.getBusinessPartner()?.getBusinessPartnerId();

    // Try to find business partner ID for existing dpNo and outletNo
    userData?.getBusinessPartners().forEach((partner: IBusinessPartner): void => {
      if (partner.getDistributionPartnerNumber() === distributionPartnerNumber
        && partner.getOutletNumber() === outletNumber) {
        businessPartnerId = partner.getBusinessPartnerId();
      }
    });
    return businessPartnerId;
  }

  /**
   * Gets the service case with the given external id.
   * @param id external id
   */
  private httpGetServiceCase(id: string): Observable<Object> {
    return this.httpClient.get('/api/service-case/v1/' + id, {withCredentials: true});
  }

  /**
   * Gets all service cases that map the given VIN. In B2D context the service cases are limited to the user's outlet.
   * In B2E context the service cases are limited to the user's id.
   * @param vin vehicle identification number
   */
  private httpGetServiceCasesByVin(vin: string): Observable<Object> {
    const params: URLSearchParams = ServiceCaseService.getServiceCasesByVinParams(
      vin,
      this.userService.userSubject.getValue()
    );
    return this.httpClient.get('/api/service-case/v3?' + params, {withCredentials: true});
  }

  /**
   * Persists the given service case in the backend.
   * @param serviceCase service case to persist
   */
  private httpCreateServiceCase(serviceCase: ServiceCase): Observable<Object> {
    const serviceCaseForBackend: ServiceCase = ServiceCaseService.removeExcessServiceCasePropertiesForUpdatingMetadata(
      serviceCase);
    return this.httpClient.post('/api/service-case/v1', serviceCaseForBackend);
  }

  /**
   * Updates the given service case in the backend.
   * @param serviceCase service case to update
   */
  private httpUpdateMetadata(serviceCase: ServiceCase): Observable<Object> {
    const serviceCaseForBackend: ServiceCase = ServiceCaseService.removeExcessServiceCasePropertiesForUpdatingMetadata(
      serviceCase);
    return this.httpClient.put('/api/service-case/v3/metadata', serviceCaseForBackend);
  }

  /**
   * Updates the given job in a service case in the backend.
   * @param serviceCaseId external id of service case
   * @param job job to update
   */
  private httpUpdateJob(serviceCaseId: string, job: Job): Observable<Object> {
    return this.httpClient.put('/api/service-case/v3/' + serviceCaseId + '/jobs', job);
  }

  /**
   * Deletes the given job in a service case in the backend.
   * @param serviceCaseId external id of service case
   * @param jobId external if of job to delete
   */
  private httpDeleteJob(serviceCaseId: string, jobId: string): Observable<Object> {
    return this.httpClient.delete('/api/service-case/v3/' + serviceCaseId + '/jobs/' + jobId);
  }
}
