import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ICase, IJob} from '@service-and-repairs/awpintegrationlib';
import {AuthInfo} from '../../../../auth/auth-info';
import {AuthInfoFactory} from '../../../../auth/auth-info-factory';
import {AuthenticationTokenBearerService} from '../../../../auth/authentication-token-bearer.service';
import {LeadsConfiguration} from '../../../../core/configuration/interfaces/leads-configuration';
import {ConfigurationLoader} from '../../../../core/configuration/services/configuration.loader';
import {ServiceCaseHolder} from '../../../../core/service-case/models/service-case-holder';
import {ServiceCaseUpdater} from '../../../../core/service-case/models/service-case-updater';
import {UserData} from '../../../../core/user/models/user-data';
import {UserService} from '../../../../core/user/services/user.service';
import {Util} from '../../../../util/util';

export const LOCAL_STORAGE_KEY_FOR_SHORTLIST_PINNED_STATE = 'shortlistPinned';

@Component({
  selector: 'app-shortlist',
  templateUrl: './shortlist.component.html',
  styleUrls: ['./shortlist.component.scss']
})
export class ShortlistComponent implements OnInit {
  @Input()
  shortlistViewState = {visible: false};

  @HostBinding('class.pinned')
  isPinned: boolean = false;

  showLoadingIndicator: boolean = true;
  showErrorIndicator: boolean = false;

  numberOfElementsInActiveCase: number;

  leadsConfiguration: LeadsConfiguration;

  user: UserData;
  authInfo: AuthInfo;
  locale: string;

  private activeCaseId: string;

  constructor(public serviceCaseHolder: ServiceCaseHolder,
              private serviceCaseUpdater: ServiceCaseUpdater,
              private router: Router,
              userService: UserService,
              public configurationLoader: ConfigurationLoader,
              private authenticationTokenBearerService: AuthenticationTokenBearerService) {
    userService.userSubject.subscribe((user: UserData) => {
      this.user = user;
      this.locale = user.getLocale();
    });
    this.onAuthTokenChanged();
    this.authenticationTokenBearerService.oidcTokenSubject.subscribe(this.onAuthTokenChanged.bind(this));
    this.authenticationTokenBearerService.csslTokenSubject.subscribe(this.onAuthTokenChanged.bind(this));
    this.configurationLoader.configChanged.subscribe((config) => this.leadsConfiguration = config.outletRelatedConfig?.leadsDealerConfiguration);
  }

  ngOnInit(): void {
    this.loadServiceCaseFrontendSource();
    this.subscribeToTabManagerCases();
    this.restorePinnedStateFromLocalStorage();
  }

  togglePinnedState(): void {
    this.isPinned = !this.isPinned;
    localStorage.setItem(LOCAL_STORAGE_KEY_FOR_SHORTLIST_PINNED_STATE, JSON.stringify(this.isPinned));
  }

  openServiceCaseDetails(): void {
    this.router.navigateByUrl('casedetails').then(() => {
      this.close();
    });
  }

  close(): void {
    this.shortlistViewState.visible = false;
  }

  isHidden(serviceCase: ICase): boolean {
    return !serviceCase || (this.activeCaseId !== serviceCase.getExternalId());
  }

  showFlatRateUnitAmount(): boolean {
    return this.configurationLoader.outletConfigurationForClientLib?.getShowFlatRateUnitAmount();
  }

  private loadServiceCaseFrontendSource(): void {
    Util.attachScript('/ui/service-case/main.js')
      .catch(() => {
        this.showErrorIndicator = true;
      })
      .finally(() => {
        this.showLoadingIndicator = false;
      });
  }

  private subscribeToTabManagerCases(): void {
    this.serviceCaseHolder.activeServiceCaseChanged.subscribe(this.onCaseTabsChange.bind(this));
    this.serviceCaseHolder.serviceCaseClosed.subscribe(this.onCaseTabsChange.bind(this));
  }

  private onCaseTabsChange(): void {
    this.activeCaseId = this.serviceCaseHolder.getActiveCase()?.getExternalId();
    this.numberOfElementsInActiveCase = this.serviceCaseHolder.getActiveCase()?.getNumberOfElements();
  }

  private restorePinnedStateFromLocalStorage(): void {
    try {
      this.isPinned = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_FOR_SHORTLIST_PINNED_STATE));
    } catch (e) {
      // isPinned is false by default
    }
  }

  private onAuthTokenChanged(): void {
    if (this.user) {
      this.authInfo = AuthInfoFactory.makeAuthInfo(this.user);
    } else {
      this.authInfo = undefined;
    }
  }

  onJobsDeleted(jobs: IJob[]): void {
    this.serviceCaseUpdater.removeJobsFromServiceCase(jobs);
  }
}
