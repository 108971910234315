import {formatDate} from '@angular/common';
import {Component, EventEmitter, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  RowDoubleClickedEvent,
  ValueFormatterParams
} from 'ag-grid-community';
import {KeyData} from '../../core/keydata/interfaces/key-data';
import {KeyDataLoader} from '../../core/keydata/services/key-data.loader';
import {UserData} from '../../core/user/models/user-data';
import {UserService} from '../../core/user/services/user.service';
import {getColumnDefinitions, getDefaultColumnDefinition} from './key-pool-column-definitions';

const LOCAL_STORAGE_KEY_FOR_PERSONAL_KEY_READ_FILTER = 'filterToPersonalKeyReader';

@Component({
  selector: 'app-key-pool',
  templateUrl: './key-pool.component.html',
  styleUrls: ['./key-pool.component.scss']
})
export class KeyPoolComponent {

  @Output()
  vinSelected = new EventEmitter<string>();

  user: UserData;
  searchAsYouTypeInput: string;
  keyData: KeyData[];
  visibleKeyData: KeyData[];
  filterToPersonalKeyReader: boolean;
  showErrorIndicator: boolean;

  gridOptions: GridOptions;
  gridApi: GridApi;
  columnDefs: any;

  private readonly kilometerPerMiles = 1.609344; // exact the same factor is used in ISPA.Next

  private static isKeyDataOutdated(keyData: KeyData): boolean {
    if (keyData.keyOrigin !== 'KEY_READER' || keyData.brand === 'BMW MOTORRAD') {
      // only show warning for physical key read of cars
      return false;
    }
    return keyData.writeDate === undefined || (Date.now() - new Date(keyData.writeDate).getTime() > 24 * 3600 * 1000);
  }

  constructor(private translate: TranslateService,
              private keyDataLoader: KeyDataLoader,
              userService: UserService) {
    userService.userSubject.subscribe(this.onUserChange.bind(this));

    this.filterToPersonalKeyReader =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_FOR_PERSONAL_KEY_READ_FILTER)) || false;

    this.gridOptions = {
      defaultColDef: getDefaultColumnDefinition(
        this.translate,
        KeyPoolComponent.isKeyDataOutdated,
        this.searchAsYouTypeCellRenderer.bind(this)
      ),
      tooltipShowDelay: 0
    };

    this.createColumns();
    this.translate.onLangChange.subscribe(this.createColumns.bind(this));

    this.keyDataLoader.keyDataChanged.subscribe({
      next: (keyData: KeyData[]) => this.updateKeyData(keyData),
      error: () => this.showErrorIndicator = true
    });
  }

  rowDoubleClicked(event: RowDoubleClickedEvent): void {
    this.vinSelected.emit(event.data.vin);
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
  }

  keyReaderSwitchChanged(): void {
    localStorage.setItem(LOCAL_STORAGE_KEY_FOR_PERSONAL_KEY_READ_FILTER, this.filterToPersonalKeyReader.toString());
    this.updateKeyData();
  }

  triggerKeyDataReload(): void {
    this.visibleKeyData = undefined;
    this.keyData = undefined;
    this.keyDataLoader.reloadKeyData();
  }

  searchAsYouType(): void {
    this.gridApi?.setGridOption('quickFilterText', this.searchAsYouTypeInput);
    this.gridApi?.refreshCells({force: true});
  }

  private onUserChange(user: UserData) {
    this.user = user;
    this.updateKeyData();
  }

  private createColumns(): void {
    this.columnDefs = getColumnDefinitions(
      this.translate,
      this.user?.getMileageUnit(),
      this.shortDateFormatter.bind(this),
      this.shortDateTimeFormatter.bind(this),
      this.dateComparator.bind(this),
      this.mapKeyReaderOrigin.bind(this),
      KeyPoolComponent.isKeyDataOutdated
    );
  }

  private searchAsYouTypeCellRenderer(params: ICellRendererParams): string {
    let value = params.valueFormatted || params.value;
    const pattern = this.searchAsYouTypeInput?.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regExpResult = new RegExp(pattern, 'i').exec(value);
    if (regExpResult && value) {
      return value.replace(regExpResult, `<b>${regExpResult.toString()}</b>`);
    }
    return value;
  }

  private updateKeyData(keyData?: KeyData[]): void {
    if (keyData) {
      this.keyData = keyData;
    }
    const filteredKeyData = this.filterKeyData();
    this.setMileage(filteredKeyData);
    this.visibleKeyData = filteredKeyData;
    setTimeout(this.searchAsYouType, 100);
  }

  private filterKeyData(): KeyData[] {
    if (!this.keyData) {
      return [];
    }
    const brands = this.getBrands();
    const brandFilteredKeyData = this.keyData.filter(keyDataEntry => brands.includes(keyDataEntry.brand));
    if (this.user?.getKeyReaderId() && this.filterToPersonalKeyReader) {
      return brandFilteredKeyData.filter(keyDataEntry => keyDataEntry.keyReaderId === this.user.getKeyReaderId());
    }
    return brandFilteredKeyData;
  }

  private getBrands(): string[] {
    switch (this.user?.getSelectedVehicleType()) {
      case 'PASSENGER_CAR': {
        return ['BMW PKW', 'BMW I', 'ROLLS-ROYCE PKW', 'MINI PKW'];
      }
      case 'MOTORCYCLE': {
        return ['BMW MOTORRAD'];
      }
      default: {
        return ['BMW MOTORRAD', 'BMW PKW', 'BMW I', 'ROLLS-ROYCE PKW', 'MINI PKW'];
      }
    }
  }

  private shortDateFormatter(params: ValueFormatterParams): string {
    return this.formatDateString(params.value, 'shortDate', this.getTimeZoneOffset());
  }

  private shortDateTimeFormatter(params: ValueFormatterParams): string {
    return this.formatDateString(params.value, 'short', this.getTimeZoneOffset());
  }

  private dateComparator(date1: string, date2: string): number {
    // catch empty dates
    if (isNaN(new Date(date1).valueOf()) && isNaN(new Date(date2).valueOf())) {
      return 0;
    } else if (isNaN(new Date(date1).valueOf())) {
      return -1;
    } else if (isNaN(new Date(date2).valueOf())) {
      return 1;
    }

    // by default dates would be compared as strings
    return new Date(date1).valueOf() - new Date(date2).valueOf();
  }

  /**
   * @param offsetMinutes Optional time zone offset in minutes, e.g. -120
   * @returns e.g. +0800 for UTC/GMT + 8 hours
   */
  private getTimeZoneOffset(offsetMinutes?: number): string {
    const pad = (offset: number) => (offset < 10 ? '0' : '') + offset;
    let offset = (offsetMinutes || offsetMinutes === 0) ? offsetMinutes : new Date().getTimezoneOffset();
    const sign = offset <= 0 ? '+' : '-';
    offset = Math.abs(offset);
    return sign + pad(offset / 60 | 0) + pad(offset % 60);
  }

  private formatDateString(date: string, format: string, timeZone?: string): string {
    try {
      return date === undefined ? '' : formatDate(date, format, this.user?.getLocale(), timeZone);
    } catch (err) {
      return '';
    }
  }

  private mapKeyReaderOrigin(params: ValueFormatterParams): string {
    switch (params.value) {
      case 'KEY_READER':
        return this.translate.instant('keyPool.originKeyReader');
      case 'REMOTE_KEY_READ':
        return this.translate.instant('keyPool.originRemoteKeyRead');
      case 'TELESERVICES':
        return this.translate.instant('keyPool.originTeleServices');
      case 'VIN_KEYCARD':
        return this.translate.instant('keyPool.originVinKeyCard');
      default:
        return '';
    }
  }

  private setMileage(keyDataInKilometers: KeyData[]): void {
    keyDataInKilometers?.forEach(entry => {
      const totalDistance =
        this.user?.getMileageUnit() === 'mi' ?
          entry.totalDistanceInKm / this.kilometerPerMiles :
          entry.totalDistanceInKm;
      entry.totalDistance = Math.round(totalDistance).toLocaleString(this.user?.getLocale()) +
        ' ' +
        this.user?.getMileageUnit();
    });
  }
}
